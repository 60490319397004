import React from "react";
import PdfSection from "../components/atoms/PdfSection/PdfSection";
import ProductsCases from "../components/ogranisms/ProductsCases/ProductsCases";
function UserInspirations(){
    return(
        <>
        <PdfSection extraClass={"uses"}  title="" />
        <ProductsCases />
        </>
    );
}
export default UserInspirations;