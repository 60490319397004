import { graphql, useStaticQuery } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as styles from "./ProductsCases.module.scss";
import Slider from "react-slick";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
import FullScreen from "../../../svg/fullscreen.svg";
import ExitFullScreen from "../../../svg/exitfullscreen.svg";
import nocontent from "../../../images/nocontentslide.png";
import { FiSearch } from "react-icons/fi";
import { Link } from "@reach/router";
import { BsFillPlayFill } from "react-icons/bs";
import { data } from "../../../constants/explainervideosData";
import VideoThumbnail from "../../molecules/VideoThumbnail/VideoThumbnail";
import logo from "../../../images/getwizr2.png";
import { BsInfoLg } from "react-icons/bs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import gif from "../../../images/loader-1.gif";
import Close from "../../../svg/close.svg";
import MainFAQ from "../MainFAQ/MainFAQ";
import PaymentSection from "../PaymentSection/PaymentSection";
import { IoIosArrowDown } from "react-icons/io";
import { BsDownload } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { BsSkipStart } from "react-icons/bs";
import defaultimg from "../../../images/user-start.png";
import { useMediaQuery } from "react-responsive";
import {AiOutlineMenu} from "react-icons/ai";
function ProductsCases() {
  const { allWpProduct } = useStaticQuery(
    graphql`
      query {
        allWpProduct {
          edges {
            node {
              title
              tags {
                nodes {
                  name
                }
              }
              categoryImages {
                categoryVideo
                storyData {
                  storyTitle
                  storyText
                  storyImage {
                    node {
                      sourceUrl
                    }
                  }
                }
                imageText {
                  text
                  itemTags
                  livePreview
                  image {
                    node {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const [activeTab, setActiveTab] = useState("Default");
  const [activeSubTab, setActiveSubTab] = useState("");
  const [, setActiveSlide] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [, setNumberOfSlides] = useState(0);
  const [activeImage, setActiveImage] = useState("");
  const [activeText, setActiveText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [itemNumber, setItemNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [videoTab, setVideoTab] = useState(false);
  const [faqTab, setFaqTab] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStory, setSelectedStory] = useState({
    text: "",
    image: "",
    title: "",
  });
  const [dropdown, setDropdown] = useState(false);
  const [plans, setPlans] = useState(false);
  const [licenses, setLicenses] = useState(false);
  const [selectLicense, setSelectLicense] = useState("Licenses");
  const [recommendedDropdown, setRecommendedDropdown] = useState(false);
  const [recommended, setRecommended] = useState("Recommended");
const [menu,setMenu] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 990 });

  //explainer videos
  const [selectedButton, setSelectedButton] = useState("See All");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }

  const videoResults = data.filter(
    (result) =>
      result.serie.toLowerCase().includes(searchTerm.toLowerCase()) ||
      result.videos.some((video) =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );
  const [offset, setOffset] = useState(0);
  const filteredData = videoResults.filter((item) => {
    if (selectedButton === "See All") {
      return true;
    } else {
      return item.tab === selectedButton;
    }
  });

  useEffect(() => {
    if (allWpProduct) {
      setIsLoading(false);
    }
  }, [allWpProduct]);

  const videoSubTabs = [
    {
      item: "See All",
    },
    {
      item: "Student License",
    },
    {
      item: "Personal License",
    },
    {
      item: "Professional License",
    },
    {
      item: "Recommended at startup",
    },
    {
      item: "Presentation templates",
    },
  ];
  //end of explainer

  useEffect(() => {
    const results = allWpProduct.edges.filter(({ node }) => {
      const titleMatch = node.title
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const itemTagsMatch = node.category_images?.imageText?.some((item) =>
        item?.itemTags?.join().toLowerCase().includes(searchQuery.toLowerCase())
      );
      return titleMatch || itemTagsMatch;
    });
    setSearchResults(results);
  }, [allWpProduct.edges, searchQuery]);

  const handleSlideItemClick = useCallback(
    (url, text, index) => {
      setActiveImage(url);
      setActiveText(text);
      setActiveIndex(index);
    },
    [setActiveImage, setActiveText, setActiveIndex]
  );
  useEffect(() => {
    const firstImage = searchResults.find(({ node }) => {
      const hasMatchingTag = node.tags.nodes.some(
        ({ name }) => name === activeTab
      );
      const hasMatchingSubtab = activeSubTab
        ? node.title === activeSubTab
        : true;
      const hasMatchingSearchQuery = searchQuery
        ? node.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (node.category_images?.imageText?.some(
            ({ text, itemTags }) =>
              text?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              itemTags?.join().toLowerCase().includes(searchQuery.toLowerCase())
          ) ??
            false)
        : true;
      return hasMatchingTag && hasMatchingSubtab && hasMatchingSearchQuery;
    });
    const firstImageUrl =
      (firstImage?.node?.category_images?.imageText?.[0]?.image?.sourceUrl ??
        "") ||
      (searchResults[0]?.node?.category_images?.imageText?.[0]?.image
        ?.sourceUrl ??
        "");
    const firstTextUrl =
      (firstImage?.node?.category_images?.imageText?.[0]?.text ?? "") ||
      (searchResults[0]?.node?.category_images?.imageText?.[0]?.text ?? "");
    setActiveText(firstTextUrl);
    setActiveImage(firstImageUrl);
  }, [activeTab, activeSubTab, searchQuery, searchResults]);

  const sliderRef = useRef(null);
  const toggleFullScreen = () => {
    if (typeof document !== "undefined") {
      if (fullScreen) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
      setFullScreen(!fullScreen);
    }
  };
  const filteredProducts = allWpProduct.edges.filter(({ node }) =>
    node.tags.nodes.find(({ name }) => name === activeTab)
  );
  const handleSubTabClick = (title) => {
    setActiveSubTab(title);
  };
  const handleBeforeChange = (oldIndex, newIndex) => {
    setActiveSlide(newIndex);
  };

  const handleAfterChange = (index) => {
    setNumberOfSlides(index + 1);
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    sliderRef.current?.slickGoTo(0);
  };

  const allImages = allWpProduct.edges
    .filter(({ node }) =>
      node.tags.nodes.find(({ name }) => name === activeTab)
    )
    .map(({ node }) =>
      node.category_images?.imageText
        ?.filter((item) => (activeSubTab ? item.text === activeSubTab : true))
        .map((item) => item?.image?.sourceUrl)
    )
    .flat();

  const totalImages = allImages.length;

  const allImagesSearch = searchResults
    .map(({ node }) =>
      node.category_images?.imageText?.map((item) => item?.image?.sourceUrl)
    )
    .flat();

  const totalImagesSearch = allImagesSearch?.length;
  const subtaballImages = filteredProducts
    .filter(({ node }) => node.title === activeSubTab)
    .map(({ node }) =>
      node.category_images?.imageText?.map((item) => item?.image?.sourceUrl)
    )
    .flat();
  const subtabTotalimages = subtaballImages.length;
  const slidesNumber =
    activeSubTab === "" && searchQuery === ""
      ? totalImages
      : activeSubTab !== "" && searchQuery === ""
      ? subtabTotalimages
      : searchQuery !== "" && totalImagesSearch;
  const [containerInView, setContainerInView] = useState(false);
  const sliderSearch = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesNumber < 4 ? 3 : isSmallScreen ? 1 : 4,
    slidesToScroll: 1,
    arrows: true,
    // fade: true,
    prevArrow: (
      <div>
        <ArrowLeft />
      </div>
    ),
    nextArrow: (
      <div>
        <ArrowRight />
      </div>
    ),
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setContainerInView(true);
          } else {
            setContainerInView(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(document.querySelector("#container"));

    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const [storyIndex, setStoryIndex] = useState(0);

  const filteredProductsWithStoryData = allWpProduct.edges.filter(
    ({ node }) => node.category_images?.storyData?.storyTitle !== null
  );
  const filteredProductsWithVideo = allWpProduct.edges.filter(
    ({ node }) => node.category_images?.categoryVideo !== null
  );
  const handleShowNextStory = () => {
    setStoryIndex(storyIndex + 1);
    for (
      let i = storyIndex + 1;
      i < filteredProductsWithStoryData.length;
      i++
    ) {
      const storyTitle =
        filteredProductsWithStoryData[i]?.node?.category_images?.storyData
          ?.storyTitle;
      const storyImage =
        filteredProductsWithStoryData[i]?.node?.category_images?.storyData
          ?.storyImage;
      const storyText =
        filteredProductsWithStoryData[i]?.node?.category_images?.storyData
          ?.storyText;

      console.log(
        "checking story",
        filteredProductsWithStoryData[i],
        storyTitle,
        i
      );
      if (storyTitle && storyImage && storyText) {
        setSelectedStory({
          title: storyTitle,
          image: storyImage.sourceUrl,
          text: storyText,
        });
        setStoryIndex(i);
        break;
      }
    }
  };
  const handleShowPrevStory = () => {
    setStoryIndex(storyIndex - 1);
    for (
      let i = storyIndex - 1;
      i < filteredProductsWithStoryData.length;
      i++
    ) {
      const storyTitle =
        filteredProductsWithStoryData[i]?.node?.category_images?.storyData
          ?.storyTitle;
      const storyImage =
        filteredProductsWithStoryData[i]?.node?.category_images?.storyData
          ?.storyImage;
      const storyText =
        filteredProductsWithStoryData[i]?.node?.category_images?.storyData
          ?.storyText;

      if (storyTitle && storyImage && storyText) {
        setSelectedStory({
          title: storyTitle,
          image: storyImage.sourceUrl,
          text: storyText,
        });
        setStoryIndex(i);
        break;
      }
    }
  };
  const handleShowPrevVideo = () => {
    setStoryIndex(storyIndex - 1);
    for (let i = storyIndex - 1; i < filteredProductsWithVideo.length; i++) {
      const video =
        filteredProductsWithVideo[i]?.node?.category_images?.categoryVideo;
      if (video) {
        setSelectedVideo(video);
        setStoryIndex(i);
        break;
      }
    }
  };

  const handleShowNextVideo = () => {
    setStoryIndex(storyIndex + 1);
    for (let i = storyIndex + 1; i < filteredProductsWithVideo.length; i++) {
      const video =
        filteredProductsWithVideo[i]?.node?.category_images?.categoryVideo;

      if (video) {
        setSelectedVideo(video);
        setStoryIndex(i);
        break;
      }
    }
  };


  return (
    <>
      <button className={styles.toggle} onClick={toggleFullScreen}>
        {fullScreen ? <ExitFullScreen /> : <FullScreen />}
      </button>
      <div
        id="container"
        className={
          fullScreen
            ? `${styles.container} ${styles.fullContainer} fullContainerSlider`
            : styles.container
        }
      >
        {isLoading ? (
          <div className={styles.loading}>
            <img src={gif} alt="gif"/>
          </div>
        ) : (
          <>
        {isSmallScreen ?
        <div className={`${styles.trigger} ${styles.topFixed}`}>
             <div className={styles.tabContainer}>
              <div className={styles.tabs}>
                <button
                  className={styles.licenseBtn}
                  onClick={() => {
                    setLicenses(!licenses);
                    setRecommendedDropdown(false);
                  }}
                >
                  {selectLicense} <IoIosArrowDown />
                </button>
                {licenses && (
                  <div className={styles.licensesDropdown}>
                    <button
                      onClick={() => {
                        setActiveTab("Student");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setSelectLicense("Student");
                      }}
                      className={
                        activeTab === "Student" ? styles.selected : ``
                      }
                    >
                      Student
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("Personal");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setSelectLicense("Personal");
                      }}
                      className={
                        activeTab === "Personal" ? styles.selected : ``
                      }
                    >
                      Personal
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("Professional");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setSelectLicense("Professional");
                      }}
                      className={
                        activeTab === "Professional" ? styles.selected : ``
                      }
                    >
                      Professional
                    </button>
                  </div>
                )}
                <button
                  className={styles.licenseBtn}
                  onClick={() => {
                    setRecommendedDropdown(!recommendedDropdown);
                    setLicenses(false);
                  }}
                >
                  {recommended} <IoIosArrowDown />
                </button>
                {recommendedDropdown && (
                  <div
                    className={`${styles.licensesDropdown} ${styles.recommendedDropdown}`}
                  >
                    <button
                      onClick={() => {
                        setActiveTab("Series");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setRecommended("Series");
                      }}
                      className={
                        activeTab === "Series" ? styles.selected : ``
                      }
                    >
                      Series
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("More ideas");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setRecommended("More ideas");
                      }}
                      className={
                        activeTab === "More ideas" ? styles.selected : ``
                      }
                    >
                      More ideas
                    </button>
                  </div>
                )}
                <button
                  className={
                    videoTab ? `${styles.selected} ${styles.selectedVD}` : ``
                  }
                  onClick={() => {
                    setActiveTab("");
                    setActiveSubTab("");
                    setSearchQuery("");
                    setActiveSlide(0);
                    sliderRef.current?.slickGoTo(0);
                    setVideoTab(true);
                    setFaqTab(false);
                    setPlans(false);
                    setMenu(false)
                  }}
                >
                  All Videos
                </button>
                <div
                  className={
                    videoTab
                      ? `${styles.topsearch} ${styles.vdSearch}`
                      : styles.topsearch
                  }
                >
                  <FiSearch />
                  <input
                    placeholder={
                      videoTab ? "Search Videos" : "Search User Cases"
                    }
                    type={"text"}
                    value={videoTab ? searchTerm : searchQuery}
                    onChange={
                      videoTab ? handleSearchChange : handleSearchInputChange
                    }
                  />
                </div>
                <button
                  onClick={() => {
                    setFaqTab(true);
                    setActiveSubTab("");
                    setActiveTab("");
                    setVideoTab(false);
                    setPlans(false);
                    setMenu(false)
                  }}
                >
                  FAQ{" "}
                </button>
                <button
                  onClick={() => {
                    setPlans(true);
                    setActiveSubTab("");
                    setActiveTab("");
                    setVideoTab(false);
                    setFaqTab(false);
                    setMenu(false)
                  }}
                >
                  Plans{" "}
                </button>
              </div>
           {activeTab!=="Default" &&  <button className={styles.rightMenu} onClick={()=>setMenu(!menu)}><AiOutlineMenu /></button>}
            </div>
            <div className={menu ? `${styles.subtabMenu} ${styles.openMenu}` : styles.subtabMenu}>
              <button onClick={()=>setMenu(!menu)} className={styles.close}><Close/></button>
              <div className={styles.subTabsContainer}>
              <div className={styles.subTabs}>
                {!videoTab
                  ? allWpProduct.edges
                      .filter(({ node }) =>
                        node.tags.nodes.find(({ name }) => name === activeTab)
                      )
                      .map(({ node }) => (
                        <div key={node.title}>
                          <button
                            className={`${styles.subtab} ${
                              activeSubTab === node.title
                                ? styles.activeSubtab
                                : ""
                            }`}
                            onClick={() => {
                              handleSubTabClick(node.title);
                              sliderRef.current?.slickGoTo(0);
                              setSearchQuery("");
                              setItemNumber(1);
                            }}
                          >
                            {node.title}
                          </button>
                        </div>
                      ))
                  : videoSubTabs?.map((item, i) => {
                      return (
                        <button
                          className={`${styles.subtab} ${
                            activeSubTab === item.item
                              ? styles.activeSubtab
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            setActiveSubTab(item.item);
                            setSelectedButton(item.item);
                            setSearchTerm("");
                          }}
                        >
                          {item.item}
                        </button>
                      );
                    })}
              </div>
            </div>
            </div>
        </div> :
            <div
            className={
              containerInView || videoTab
                ? `${styles.topFixed} ${styles.trigger}`
                : styles.topFixed
            }
          >
            <Link to="/getwizr/" className={styles.logo}>
              <img src={logo} alt="logo"/>
              <p>Exit User Cases</p>
            </Link>
            <div className={styles.tabContainer}>
              <div className={styles.tabs}>
                <button
                  className={styles.licenseBtn}
                  onClick={() => {
                    setLicenses(!licenses);
                    setRecommendedDropdown(false);
                  }}
                >
                  {selectLicense} <IoIosArrowDown />
                </button>
                {licenses && (
                  <div className={styles.licensesDropdown}>
                    <button
                      onClick={() => {
                        setActiveTab("Student");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setSelectLicense("Student");
                      }}
                      className={
                        activeTab === "Student" ? styles.selected : ``
                      }
                    >
                      Student
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("Personal");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setSelectLicense("Personal");
                      }}
                      className={
                        activeTab === "Personal" ? styles.selected : ``
                      }
                    >
                      Personal
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("Professional");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setSelectLicense("Professional");
                      }}
                      className={
                        activeTab === "Professional" ? styles.selected : ``
                      }
                    >
                      Professional
                    </button>
                  </div>
                )}
                <button
                  className={styles.licenseBtn}
                  onClick={() => {
                    setRecommendedDropdown(!recommendedDropdown);
                    setLicenses(false);
                  }}
                >
                  {recommended} <IoIosArrowDown />
                </button>
                {recommendedDropdown && (
                  <div
                    className={`${styles.licensesDropdown} ${styles.recommendedDropdown}`}
                  >
                    <button
                      onClick={() => {
                        setActiveTab("Series");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setRecommended("Series");
                      }}
                      className={
                        activeTab === "Series" ? styles.selected : ``
                      }
                    >
                      Series
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("More ideas");
                        setActiveSubTab("");
                        setSearchQuery("");
                        setActiveSlide(0);
                        sliderRef.current?.slickGoTo(0);
                        setVideoTab(false);
                        setFaqTab(false);
                        setPlans(false);
                        setRecommended("More ideas");
                      }}
                      className={
                        activeTab === "More ideas" ? styles.selected : ``
                      }
                    >
                      More ideas
                    </button>
                  </div>
                )}
                <button
                  className={
                    videoTab ? `${styles.selected} ${styles.selectedVD}` : ``
                  }
                  onClick={() => {
                    setActiveTab("");
                    setActiveSubTab("");
                    setSearchQuery("");
                    setActiveSlide(0);
                    sliderRef.current?.slickGoTo(0);
                    setVideoTab(true);
                    setFaqTab(false);
                    setPlans(false);
                  }}
                >
                  All Videos
                </button>
                <div
                  className={
                    videoTab
                      ? `${styles.topsearch} ${styles.vdSearch}`
                      : styles.topsearch
                  }
                >
                  <FiSearch />
                  <input
                    placeholder={
                      videoTab ? "Search Videos" : "Search User Cases"
                    }
                    type={"text"}
                    value={videoTab ? searchTerm : searchQuery}
                    onChange={
                      videoTab ? handleSearchChange : handleSearchInputChange
                    }
                  />
                </div>
                <button
                  onClick={() => {
                    setFaqTab(true);
                    setActiveSubTab("");
                    setActiveTab("");
                    setVideoTab(false);
                    setPlans(false);
                  }}
                >
                  FAQ{" "}
                </button>
                <button
                  onClick={() => {
                    setPlans(true);
                    setActiveSubTab("");
                    setActiveTab("");
                    setVideoTab(false);
                    setFaqTab(false);
                  }}
                >
                  Plans{" "}
                </button>
              </div>
            </div>
            <div className={styles.subTabsContainer}>
              <div className={styles.subTabs}>
                {!videoTab
                  ? allWpProduct.edges
                      .filter(({ node }) =>
                        node.tags.nodes.find(({ name }) => name === activeTab)
                      )
                      .map(({ node }) => (
                        <div key={node.title}>
                          <button
                            className={`${styles.subtab} ${
                              activeSubTab === node.title
                                ? styles.activeSubtab
                                : ""
                            }`}
                            onClick={() => {
                              handleSubTabClick(node.title);
                              sliderRef.current?.slickGoTo(0);
                              setSearchQuery("");
                              setItemNumber(1);
                            }}
                          >
                            {node.title}
                          </button>
                        </div>
                      ))
                  : videoSubTabs?.map((item, i) => {
                      return (
                        <button
                          className={`${styles.subtab} ${
                            activeSubTab === item.item
                              ? styles.activeSubtab
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            setActiveSubTab(item.item);
                            setSelectedButton(item.item);
                            setSearchTerm("");
                          }}
                        >
                          {item.item}
                        </button>
                      );
                    })}
              </div>
            </div>
          </div>
        }
            {!videoTab && !faqTab && !plans && activeTab !== "Default" && (
              <div className={styles.frameMainPictures}>
                <div className={styles.top}>
                  <img
                  alt="topimg"
                    className={styles.topimage}
                    src={totalImagesSearch > 0 ? `${activeImage}` : nocontent}
                  />
                  <div
                    className={
                      totalImagesSearch === 0
                        ? `${styles.underImage}`
                        : styles.underImage
                    }
                  >
                    <p>{activeText}</p>
                    <div className={styles.rightDiv}>
                      <div className={styles.sliderInfo}>
                        {totalImagesSearch > 0 && <h1> {`${slidesNumber}`}</h1>}
                      </div>
                    </div>
                  </div>
                </div>
                {activeSubTab === "" && searchQuery === "" ? (
                  <div
                    className={`productsSlider searchSlider ${styles.slider} ${styles.searchslider}`}
                  >
                    <Slider
                      beforeChange={handleBeforeChange}
                      afterChange={handleAfterChange}
                      {...sliderSearch}
                      ref={sliderRef}
                    >
                      {allWpProduct.edges
                        .filter(({ node }) =>
                          node.tags.nodes.find(({ name }) => name === activeTab)
                        )
                        .map(({ node }) =>
                          node.category_images?.imageText
                            ?.filter((item) =>
                              activeSubTab ? item.text === activeSubTab : true
                            )
                            .map((item, index) => (
                              <div
                                onClick={() => {
                                  handleSlideItemClick(
                                    item?.image?.sourceUrl,
                                    item?.text,
                                    index
                                  );
                                  setItemNumber(index + 1);
                                }}
                                className={
                                  activeImage === item?.image?.sourceUrl
                                    ? `${styles.activeImage} ${styles.imageContainer}`
                                    : styles.imageContainer
                                }
                                key={index}
                              >
                                <img src={item?.image?.sourceUrl} alt="product" />
                              </div>
                            ))
                        )}
                    </Slider>
                  </div>
                ) : activeSubTab !== "" && searchQuery === "" ? (
                  <div
                    className={`productsSlider searchSlider ${styles.slider} ${styles.searchslider}`}
                  >
                    {filteredProducts
                      .filter(({ node }) => node.title === activeSubTab)
                      .map(({ node }) => {
                        const { category_images } = node;
                        const { categoryVideo, imageText, storyData } =
                          category_images || {};
                        console.log("selected video", selectedVideo);
                        const activeImageItem = imageText?.find(
                          (item) => item?.image?.sourceUrl === activeImage
                        );
                        console.log("activeImageitem", activeImageItem);
                        return (
                          <>
                            {categoryVideo !== null ||
                            storyData?.storyText !== null ? (
                              <button
                                onClick={() => setDropdown(!dropdown)}
                                className={styles.dots}
                              >
                                <BiDotsVerticalRounded />
                              </button>
                            ) : (
                              ""
                            )}
                            {dropdown && (
                              <div className={styles.categoryIcons}>
                                {categoryVideo !== null && (
                                  <button
                                    onClick={() =>
                                      setSelectedVideo(categoryVideo)
                                    }
                                  >
                                    <BsFillPlayFill /> Play Video
                                  </button>
                                )}

                                {storyData?.storyText !== null && (
                                  <button
                                    onClick={() =>
                                      setSelectedStory({
                                        text: storyData?.storyText,
                                        image: storyData?.storyImage?.sourceUrl,
                                        title: storyData?.storyTitle,
                                      })
                                    }
                                  >
                                    <BsInfoLg /> Read Story
                                  </button>
                                )}
                                {activeImageItem?.livePreview !== null && (
                                  <button>
                                    <a
                                    aria-label="redirect"
                                      target={"_blank"}
                                      href={`${activeImageItem?.livePreview}`} rel="noreferrer"
                                    >
                                      <AiFillEye /> Preview Live
                                    </a>
                                  </button>
                                )}
                              </div>
                            )}
                            <Slider
                              beforeChange={handleBeforeChange}
                              afterChange={handleAfterChange}
                              {...sliderSearch}
                              ref={sliderRef}
                            >
                              {imageText?.map((item, index) => (
                                <div
                                  onClick={() => {
                                    handleSlideItemClick(
                                      item?.image?.sourceUrl,
                                      item?.text,
                                      index
                                    );
                                    setItemNumber(index + 1);
                                  }}
                                  key={item.text}
                                  className={
                                    activeImage === item?.image?.sourceUrl
                                      ? `${styles.activeImage} ${styles.imageContainer}`
                                      : ` ${styles.imageContainer}`
                                  }
                                >
                                  <img src={item?.image?.sourceUrl} alt="product" />
                                </div>
                              ))}
                            </Slider>
                          </>
                        );
                      })}
                  </div>
                ) : (
                  searchQuery !== "" && (
                    <div
                      className={`productsSlider searchSlider ${styles.slider} ${styles.searchslider}`}
                    >
                      <Slider
                        beforeChange={handleBeforeChange}
                        afterChange={handleAfterChange}
                        {...sliderSearch}
                        ref={sliderRef}
                      >
                        {searchResults.map(({ node }) =>
                          node.category_images?.imageText?.map((item) => (
                            <div
                              className={
                                activeImage === item?.image?.sourceUrl
                                  ? `${styles.activeImage} ${styles.imageContainer}`
                                  : styles.imageContainer
                              }
                              key={item.text}
                            >
                              <img
                                onClick={() => {
                                  handleSlideItemClick(
                                    item?.image?.sourceUrl,
                                    item?.text
                                  );
                                }}
                                src={item?.image?.sourceUrl}
                                alt="product"
                              />
                            </div>
                          ))
                        )}
                      </Slider>
                    </div>
                  )
                )}
              </div>
            )}
            <div>
              {videoTab && (
                <div
                  className={` ${styles.videocontainer}   ${
                    offset >= 1000 && `${styles.container1}`
                  } ${offset >= 1800 && `${styles.container2}`} ${
                    offset >= 4000 && `${styles.container3}`
                  } ${offset >= 5000 && `${styles.container4}`}  `}
                >
                  <div
                    className={
                      selectedButton === "See All"
                        ? `${styles.main} ${styles.allData}`
                        : styles.main
                    }
                  >
                    {filteredData.map((result) => {
                      return (
                        <div className={`${styles.serie} exp-videos`}>
                          <h6>
                            {result?.serie} ({result?.videos?.length})
                          </h6>
                          <div className={styles.data}>
                            {result.videos.map((video) => (
                              <div
                                className={styles.thumbnail}
                                onClick={() => setSelectedVideo(video)}
                              >
                                <VideoThumbnail
                                  image={video?.thumbnail}
                                  desc={video?.description}
                                  tag={video?.category}
                                  extraClass={
                                    result?.extraClass
                                      ? result?.extraClass
                                      : `expvideo`
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {selectedVideo && (
              <div className={styles.popup}>
                <button
                  className={styles.close}
                  onClick={() => setSelectedVideo(null)}
                >
                  <Close />
                </button>
                <div className={styles.frame}>
                  <div className={styles.btns}>
                    <button onClick={handleShowPrevVideo}>
                      <ArrowLeft />
                    </button>

                    <button onClick={handleShowNextVideo}>
                      <ArrowRight />
                    </button>
                  </div>
                  <video
                    src={selectedVideo?.url || selectedVideo}
                    poster={selectedVideo?.poster}
                    autoPlay={selectedVideo?.url ? false : true}
                    controls={true}
                    muted={selectedVideo?.url ? false : true}
                  />
                </div>
              </div>
            )}
            {selectedStory?.text != "" && (
              <div className={styles.storypopup}>
                <button
                  className={styles.close}
                  onClick={() =>
                    setSelectedStory({ text: "", image: "", title: "" })
                  }
                >
                  <Close />
                </button>
                <div className={styles.storyframe}>
                  <div className={styles.btns}>
                    <button
                      style={{
                        cursor: filteredProductsWithStoryData[storyIndex - 1]
                          ?.node?.category_images?.storyData?.storyTitle
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={handleShowPrevStory}
                    >
                      <ArrowLeft />
                    </button>
                    <button
                      style={{
                        cursor: filteredProductsWithStoryData[storyIndex + 1]
                          ?.node?.category_images?.storyData?.storyTitle
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={handleShowNextStory}
                    >
                      <ArrowRight />
                    </button>
                  </div>

                  <h1>{selectedStory?.title}</h1>
                  <img alt="product" src={selectedStory?.image} />
                  <div className={styles.content}>
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedStory?.text }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {activeTab === "Default" && (
          <div className={styles.defaultcase}>
            <img alt="default" src={defaultimg} />
          </div>
        )}
        {faqTab && activeSubTab === "" && activeTab === "" && <MainFAQ />}
        {plans && activeSubTab === "" && activeTab === "" && <PaymentSection name="products"/>}
       {!menu && !isSmallScreen &&  <div className={styles.bottomActions}>
          <div className={styles.icon}>
            <p>Download FAQ</p>
            <button>
              <BsDownload />
            </button>
          </div>
          <div className={styles.icon}>
            <p>Request Demo</p>{" "}
            <button>
              <a
              aria-label="redirect"
               href="mailto:sales@mwizr.com">
                <CiMail />
              </a>
            </button>
          </div>
          <div className={styles.icon}>
            {" "}
            <p>Start Trial</p>
            <button>
              <BsSkipStart />
            </button>
          </div>
        </div>}
      </div>
    </>
  );
}

export default ProductsCases;
