// extracted by mini-css-extract-plugin
export var bill = "PaymentSection-module--bill--889c9";
export var bottom = "PaymentSection-module--bottom--eecc4";
export var bottomSection = "PaymentSection-module--bottomSection--a83cf";
export var card = "PaymentSection-module--card--ef7fc";
export var cards = "PaymentSection-module--cards--f5d27";
export var container = "PaymentSection-module--container--fb198";
export var content = "PaymentSection-module--content--ecb2e";
export var description = "PaymentSection-module--description--8fce9";
export var middleTitle = "PaymentSection-module--middleTitle--2770f";
export var product = "PaymentSection-module--product--4e98b";
export var products = "PaymentSection-module--products--96996";
export var shade = "PaymentSection-module--shade--90ad2";
export var toggle = "PaymentSection-module--toggle--47b2e";
export var toggleon = "PaymentSection-module--toggleon--91297";
export var top = "PaymentSection-module--top--2caaf";