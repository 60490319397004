import React, { useState } from "react";
import FaqSection from "../FaqSection";
import * as styles from "./MainFAQ.module.scss";


function MainFAQ(){
    const [tab,setTab]=useState("Terms");
    const termsData = [
        {
            question: "What inspired MASTER WiZR?",
            answer:
              "We combined the finest attributes of Apple, Spotify, Netflix, and Microsoft to redefine how people present, communicate, and meet. With Master WiZR, anyone can create mesmerizing and instinctive user interfaces to portray their ideas effectively.",
            open: true,
          },
          {
            question: "How much does it cost?",
            answer:
              "Our services include both Software (SaaS) and Presentations (PaaS). The pricing is affordable, from students and freelancers to small businesses and large corporations. Prices vary from $10-80 per month depending on usage and the number of licenses. To get started, simply send us an inquiry.",
            open: false,
          },
          {
            question: "What can I do on MASTER WiZR?",
            answer:
              "MASTER WiZR is a cloud-based presentation system that offers two modules: LIBRARY and STUDIO. The LIBRARY helps you arrange and present your content, while the STUDIO allows you to personalize and distribute your presentations in fresh new ways. Our ultimate goal is to revolutionize the art of presentations by providing a tool that streamlines content organization, presentation, and sharing processes.",
            open: false,
          },
          {
            question: "What makes MASTER WiZR unique?",
            answer:
              "We focus on visually improving your content, making it easy to organize and share. Our aim is to become the world's premier presentation and meeting tool that will boost your communication and impact. Witness the transformation with MASTER WiZR's before-and-after results.",
            open: false,
          },
    ]
    const basicsData = [
        {
            question: "Basic question 1",
            answer:
              "Putting the best of world-leading design tools - Apple, Spotify, Netflix, and Microsoft - together in one place, we're redefining the way the world presents, shares, and meets. With Master WiZR, anyone can create beautiful, intuitive user experiences to express themselves effectively.",
            open: true,
          },
          {
            question: "Basic question 2",
            answer:
              "Our services include both Software (SaaS) and Presentations (PaaS). The pricing is affordable for everyone, from students to freelancers to small businesses to large corporations. Prices vary from $10-80 per month based on usage and the number of licenses. You can also get our free version. To get started, simply send us an inquiry.",
            open: false,
          },
          {
            question: "What can I do on MASTER WiZR?",
            answer:
              "MASTER WiZR is a cloud-based presentation system consisting of two modules: LIBRARY and STUDIO. With the LIBRARY, you can organize and present the full catalog of contents in a clever way. In the STUDIO, you can select presentations from the LIBRARY to customize and share that material in a new, exciting way. MASTER WiZR helps you organize, present, and share your content more effectively. Our goal is to revolutionize presentations, not just give you a good tool.",
            open: false,
          },
          {
            question: "What makes MASTER WiZR unique?",
            answer:
              "Our aim is to make your content look better, more organized, and easier to share than ever before. MASTER WiZR aims to be the world's premier presentation and meeting tool, allowing you to communicate more effectively and make more impact. You can see the before-and-after results of your own material using MASTER WiZR.",
            open: false,
          },
    ]
    return(
    <div className={styles.main}>
     <div className={styles.tabs}>
         <button className={tab==="Terms" ? styles.active :""} onClick={()=>setTab("Terms")}>Terms</button>
         <button className={tab==="Basics" ? styles.active :""} onClick={()=>setTab("Basics")}>Basics</button>
         <button className={tab==="Advanced" ? styles.active :""} onClick={()=>setTab("Advanced")}>Advanced</button>
         <button className={tab==="Sales" ? styles.active :""} onClick={()=>setTab("Sales")}>Sales</button>
         </div>
         <div className={styles.container}>
             {tab==="Terms" ? <FaqSection  data={termsData}/> : ""}
             {tab==="Basics" ? <FaqSection  data={basicsData}/> : ""}
             {tab==="Advanced" ? <FaqSection  data={termsData}/> : ""}
             {tab==="Sales" ? <FaqSection  data={basicsData}/> : ""}

         </div>
    </div>
)}
export default MainFAQ;