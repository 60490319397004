import React, { useState } from "react";
import * as styles from "./PaymentSection.module.scss";
import image1 from "../../../images/paymentcard1.png";
import image2 from "../../../images/paymentcard2.png";
import image3 from "../../../images/paymentcard3.png";
import logo from "../../../images/mw.png";
import Library from "../../../images/library.svg";
import Studio from "../../../images/studioicon.svg";
import Connect from "../../../images/connect.svg";
import WiZRX from "../../../images/wizrx.svg";
import MPublisher from "../../../images/mpublisher.svg";
import Slider from "react-slick";
import "../../../styles/slick-theme.scss";
import "../../../styles/slick.scss";

function PaymentSection({ name }) {
  const [state, setState] = useState(false);
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };

//   const [hoverState, setHoverState] = useState();
  const data = [
    {
      license: "Student",
      price: !state ? `$5 /month` : `$60 /year`,
      subtitle: "Studio",
      description:
        "Solution tailored for students notes, thesis and collaborations.",
        bgGradient:"linear-gradient(rgba(229, 108, 20, 0.72), rgb(190, 83, 42)) 0px 0px no-repeat padding-box padding-box transparent",
      image: image1,
      content: `
            <ul> 
            <li>Single lite user </li>
            <li>Access to one portal</li>
            </ul>
            `,
            products: [
              {
                name: "Library", 
                icon: <Library />,
              },
              {
                name: "WiZR Connect", 
                icon: <Connect />,
              },
            ]

    },
    {
      license: "Personal",
      price: !state ? `$10 /month` : `$120 /year`,
      subtitle: "Library + Studio",
      description: "Solution tailored for personal use, freelancers and more.",
      bgGradient:
        "linear-gradient(rgb(6 147 218 / 77%), rgb(6, 98, 144)) 0px 0px no-repeat padding-box padding-box transparent",
      image: image2,
      content: `
            <ul> 
            <li>Single regular user </li>
            <li>Access to three portal</li>
            </ul>
            `,
            products: [
              {
                name: "Library", 
                icon: <Library />,
              },
              {
                name: "Studio", 
                icon: <Studio />,
              },
            ]
    },
    {
      license: "Professional",
      price: !state ? `$10+ /month` : `$240 /year`,
      subtitle: "Library + Studio",
      description:
        "Solution tailored for small to large companies with staff to manage.",
      bgGradient:
        "linear-gradient(rgb(76 181 158 / 68%), rgb(8, 72, 57)) 0px 0px no-repeat padding-box padding-box transparent",
      image: image3,
      content: `
            <ul> 
            <li>Multi-users </li>
            <li>Unlimited portals</li>
            <li>Options to customize</li>
            </ul>
            `,
            products: [
              {
                name: "Library", 
                icon: <Library />,
              },
              {
                name: "Studio", 
                icon: <Studio />,
              },
              {
                name: "WiZR Connect", 
                icon: <Connect />,
              },
              {
                name: "WiZR X", 
                icon: <WiZRX />,
              },
              {
                name: "MPublisher", 
                icon: <MPublisher />,
              },
            ]
    },
    // {
    //     license:"Optional Modules",
    //     price:!state ? `10 USD each` : `120 USD each`,
    //     description:`<div><span>✔</span> WiZR Connect </div> <br/> <div> <span>✔</span>  + Coming Modules </div>`,
    //     btn:"More Info",
    //     bgGradient:"linear-gradient(rgb(70 93 134 / 84%), rgb(55, 75, 110)) 0px 0px no-repeat padding-box padding-box transparent",
    //     image:image4,
    //     hoverContent:`
    //   <p>Modules coming later will enhance and enrich MASTER WiZR as a work space tools. Get started with the unique WiZR Connect, the next level in video communications. </p>
    //     `
    // },
  ];
  return (
    <div className={`${styles.container} ${styles[name]}`}>
      <h1>License Plans</h1>
      <div className={styles.bill}>
        <p>Monthly</p>
        <button
          className={
            state ? `${styles.toggle} ${styles.toggleon}` : styles.toggle
          }
          onClick={() => setState(!state)}
        ></button>
        <p>Yearly</p>
      </div>
      <div className={styles.cards}>
      <Slider {...sliderSettings} className="pricingSlider">
        {data?.map((item, i) => {
          return (
            <div
              key={i}
              style={{ backgroundImage: `url(https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706625880/Templates/Frame_1261153119_auh45r.png)` }}
              className={styles.card}
            >
              <div
                style={{ background: item.bgGradient }}
                className={styles.shade}
              ></div>
              <div className={styles.top}>
                <h3><img src={logo} alt="logo"  style={{marginRight: "10px"}}/>{item.license}</h3>
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></p>
                <h2>{item.price}</h2>
                <div className={styles.middleSection}>
                  <h3 className={styles.middleTitle}>Includes</h3>
                <span className={styles.content} dangerouslySetInnerHTML={{ __html: item.content }}></span>
                {/* <button>{item?.btn ? item.btn : `More Info`}</button> */}
                </div>
                <div className={styles.bottomSection}>
                <h3 className={styles.middleTitle}>Products</h3>
                {item.products?.map((product, j) => (
          <div key={j} className={styles.product}>
            {product?.icon && product.icon}
            <span
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: product.name }}
            ></span>
          </div>
        ))}
                </div>
              </div>
              {/* {hoverState===i ? */}
              {/* : */}
              {/* <div className={styles.bottom}>
                <h4>{item.subtitle}</h4>
                <button>{item?.btn ? item.btn : `More Info`}</button>
              </div> */}
              {/* } */}
            </div>
          );
        })}
        </Slider>
      </div>
    </div>
  );
}
export default PaymentSection;