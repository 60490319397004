import React, { useState } from "react";
import * as styles from "./faqSection.module.scss";
import Faq from "../../atoms/Faq";

const FaqSection = ({data}) => {
  const [faqs, setfaqs] = useState(data);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        }
        // If you want to close other FAQs when you click one of them just uncomment the part below
        else {
            faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h3>FAQ</h3>
        <h2>In case you missed anything</h2>
        {faqs.map((faq, i) => (
          <Faq extraClass={"caseFaq"} index={i} faq={faq} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
